* {
  margin: 0;
  padding: 0;
  list-style: none;
}

.content {
  min-height: 65vh;
  // max-width: 1088px;
  max-width: 86%;
  padding: 96px 32px;
  margin-left: auto;
  margin-right: auto;
}

.logo {
  font-size: 18px;
  font-weight: 400;
  display: flex;
  align-items: center;
  img {
    width: 32px;
    vertical-align: -8px;
    margin-right: 10px;
  }
}

.chat-demo {
  width: 40%;
}

@media only screen and (min-width: 680px) {
  .hidden-menu {
    display: none;
  }
  .show-element {
    display: block !important;
  }
  .show-flex-element {
    display: flex !important;
  }
}

@media only screen and (max-width: 800px) {
  .w100 {
    width: 100% !important;
  }
  .sub-title-padding {
    padding: 40px 0 !important;
  }
}
@media only screen and (max-width: 680px) {
  .hidden-element {
    display: none !important;
  }

  .sub-center-padding {
    padding: 48px 0 96px !important;
  }
  .sub-content-padding {
    padding: 96px 10px;
  }
  .sub-title-padding {
    padding: 40px 0 !important;
  }

  .w100 {
    width: 100% !important;
  }
}
