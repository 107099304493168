.wrap {
  max-width: 1024px;
  margin: auto;

  .center {
    padding: 96px 32px;
    display: flex;
    justify-content: center;
  }

  .formWrap {
    max-width: 512px;
    width: 320px;
    padding: 0 12px;
  }

  .link{
    text-align: center;
    a{
      display: block;
      margin-bottom: 10px;
      text-decoration: underline;
      color: gray;
    }
  }
}
