.header {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 32px 0;

  a {
    text-decoration: none;
    color: #000;
    font-size: 15px;
    font-weight: 600;
  }

  .logo {
    font-size: 18px;
    font-weight: 400;
    img {
      width: 32px;
      vertical-align: -8px;
      margin-right: 10px;
    }
  }

  .nav {
    display: flex;
    align-items: center;
    li {
      margin: 0 12px;
    }
  }

  .right {
  }
}
