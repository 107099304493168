.chat {
  min-height: 600px;
  padding: 0 16px;
  display: flex;
  flex-direction: column;

  .top {
    position: sticky;
    top: 0;

    width: 100%;
    padding: 4px;
    height: 48px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    border-bottom: 1px solid #ccc;
    margin-bottom: 16px;

    z-index: 3;

    background: #fff;
  }

  .content {
    // overflow-y: auto;
    flex-grow: 1;
    min-height: 320px;

    > div {
      display: flex;
      div {
        font-size: 16px;
        line-height: 28px;
        padding: 12px 16px;
        border-radius: 8px;
        overflow-wrap: break-word;

        max-width: 52%;
      }
    }

    .chatLeft {
      justify-content: flex-start;
      margin-bottom: 12px;

      > div {
        background-color: #f1f1f0;
        color: black;
      }
    }
    .chatRight {
      justify-content: flex-end;

      margin-bottom: 12px;

      // max-width: 65ch;

      > div {
        background-color: rgb(79, 69, 228);
        color: white;
      }
    }
  }

  .text {
    position: sticky;
    bottom: 0;
    z-index: 3;
    background: #fff;

    .tags {
      margin: 20px 0;
      span {
        font-size: 16px;
        padding: 8px 12px;
        cursor: pointer;
      }
    }

    .input {
      display: flex;
      align-items: self-end;
      justify-content: space-between;

      border-width: 1px;
      border-style: solid;
      border-color: #d9d9d9;
      border-radius: 8px;

      textarea,
      textarea:hover,
      textarea:focus {
        box-shadow: none !important;
        border: none !important;
      }

      .send {
        width: 30px;
        cursor: pointer;
        margin-bottom: 6px;
      }
    }

    .powered {
      color: #3f3f46;
      font-size: 12px;
      text-align: center;
      padding: 8px 0px;

      a {
        color: #141410;
        font-weight: 700;
      }
    }
  }
}
