.wrap {
  max-width: 1024px;
  margin: auto;

  .center {
    padding: 96px 32px;
  }


  h1{
    text-align: center;
  }

  .btn{
    text-align: right;
  }
} 
