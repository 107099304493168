.wrap {
  max-width: 1024px;
  margin: auto;

  .center {
    padding: 48px 32px 96px;
  }

  .top {
    margin-left: 10%;
  }

  .cards {
    margin-left: 10%;
    overflow: hidden;
    .card {
      float: left;
      margin-right: 10px;
      margin-top: 10px;
    }
  }
}
