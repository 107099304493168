.wrap {
  max-width: 1200px;
  margin: auto;

  .titleWrap {
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    .title {
      > h1 {
        font-size: 60px;
        text-align: center;
        span {
          color: #a370db;
        }
      }

      > p {
        margin-top: 32px;
        font-size: 20px;
        text-align: center;
      }

      > div {
        margin-top: 40px;
        text-align: center;
        button {
          font-size: 16px;
          font-weight: 600;
          background: rgb(79, 70, 229);
          height: 40px;
        }
      }
    }

    .cententRight {
      margin-left: 42px;
    }
  }

  .video {
    padding: 64px 0;
    .h4 {
      padding: 8px 0;
      text-align: center;
      font-size: 30px;
    }

    > p {
      text-align: center;
      padding-bottom: 16px;
      font-size: 16px;
      line-height: 24px;
      span {
        font-weight: 700;
      }
    }

    > div {
      padding: 32px 0;
      margin-left: auto;
      margin-right: auto;
      border: 0 solid #e5e7eb;

      video {
        border-radius: 4px;
        height: auto;
        width: 100%;
      }
    }
  }

  .demo {
    padding: 64px 0;

    max-width: 1024px;
    margin: auto;

    .h4 {
      padding: 8px 0;
      text-align: center;
      font-size: 30px;
    }

    > p {
      text-align: center;
      padding-bottom: 16px;
      font-size: 16px;
      line-height: 24px;
      span {
        font-weight: 700;
      }
    }

    > div {
      margin: 32px 0;
      margin-left: auto;
      margin-right: auto;
      border: 1px solid #e5e7eb;
    }
  }

  .icons {
    padding: 128px 0;

    .text {
      max-width: 576px;
      font-size: 36px;
      text-align: center;
      margin: 0 auto;
      font-weight: 700;
    }

    .content {
      margin-top: 80px;
      .cardMeta {
        :global {
          .ant-card-meta-title {
            white-space: pre-wrap;
            text-overflow: inherit;
            text-align: center;
          }
        }
      }
    }
  }

  .ques {
    padding: 38px 0;

    .h4,
    .h6 {
      padding: 8px 0;
      text-align: center;
      font-size: 30px;
    }

    .h6 {
      font-size: 18px;
      font-weight: 400;

      span {
        font-weight: 600;
      }
    }

    .qsContent {
      margin-top: 64px;
      display: flex;

      li {
        margin: 20px 0 30px;
      }

      > li {
        margin: 0 16px;
      }

      h3 {
        font-size: 18px;
        font-weight: 400;
      }
      p {
        margin-top: 16px;
      }
    }
  }

  .people {
    padding: 128px 0;

    .h4 {
      padding: 8px 0;
      text-align: center;
      font-size: 30px;

      margin-bottom: 80px;
    }

    .peopleCard {
      :global{
        .ant-card-body{
          padding: 32px 18px;
        }
      }


      p:nth-child(1) {
        text-align: right;
      }
      p:nth-child(2) {
        font-size: 16px;
      }
      .peo {
        margin-top: 24px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        img{
          width: 40px;
          height: 40px;
          border-radius: 50%;

        }

        .job{
          font-weight: 700;
        }
      }

      .centerText{
        font-size: 20px !important;
        font-weight: 600;
      }
      .centerPeo{
        border-top: 1px solid #1118271a;
        padding: 16px 24px;
        justify-content: flex-start;
        img{
          margin-right: 16px;
        }
      }
    }
  }
}
