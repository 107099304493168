.footer {
  padding: 0 24px;
  background-color: #f4f4f5;

  .top {
    padding: 48px 0;
    border-bottom: 1px solid #a1a1aa;

    p {
      a {
        color: #52525b;
        text-decoration: none;
        font-size: 14px;
      }
    }
  }

  .icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }

  > p {
    padding: 20px 0;
    color: #000;
    font-size: 16px;
    a {
      font-size: 16px;
      text-decoration: none;
      color: #000;
    }
  }
}
