.wrap {
  max-width: 1024px;
  margin: auto;

  .center {
    padding: 96px 0;

    > div {
      > div {
        > p {
          font-weight: 600;
          font-size: 18px;
        }

        > div {
          padding-left: 24px;
          font-size: 14px;
          line-height: 22px;
        }
      }
    }
  }

  h1,
  p {
    margin: 32px 0;
  }
}
