.wrap {
  max-width: 84%;
  margin: auto;

  .center {
    padding: 96px 32px;
    display: flex;
    justify-content: center;
  }

  .formWrap {
    max-width: 512px;
    width: 320px;
    padding: 0 12px;
  }

  h1 {
    text-align: center;
    margin-bottom: 64px;
  }

  .textWrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 460px;
    padding: 16px;
    background: #e5e7eb;
    border-radius: 8px;

    h2 {
      margin-bottom: 16px;
    }
    p {
      margin-bottom: 8px;
    }
  }

  .price {
    display: flex;
    align-items: flex-end;
    margin-bottom: 24px;
    h3 {
      font-size: 28px;
    }
  }
}
