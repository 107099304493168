.wrap {
  max-width: 1024px;
  margin: auto;

  .center {
    padding: 48px 32px 96px;
  }

  h1 {
    text-align: center;
  }
}
